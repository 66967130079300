//main colors
$textWhite: #fff;
$textBlack: #333;

//selector colors
$lavenderPurple: #eeeeff;
$pastelPink: #fbebee;

//COLD color palette
$softPink: #dc91bd;
$sharpPink: #d656ad;
$gradientCold:linear-gradient(to right, #82a8e0, #da6bbd);

//WARM color palette
$peachPink: #f989a6;
$warmPink: #e68eb9;
$sunsetPink:#e978a8;
$gradientWarm:linear-gradient(to left, #f5abab, #f170b7);

//units
$pageWidth: 720px;
$baseFontSize:14px;
$baseFontSizeMobile:12px;
$copyFontSize:11px;
$copyFontSizeMobile:9px;
